export default function CTA() {
	return (
		<div className='relative bg-secondary'>
			<div className='relative h-80 overflow-hidden bg-secondary md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2'>
				<img
					className='h-full w-full object-cover'
					src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1691329363/majestic-courts/room-pics/DJI_0158-min_ux9yyb.jpg'
					alt=''
				/>
				<svg
					viewBox='0 0 926 676'
					aria-hidden='true'
					className='absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]'
				>
					<path
						fill='url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)'
						fillOpacity='.4'
						d='m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z'
					/>
					<defs>
						<linearGradient
							id='60c3c621-93e0-4a09-a0e6-4c228a0116d8'
							x1='926.392'
							x2='-109.635'
							y1='.176'
							y2='321.024'
							gradientUnits='userSpaceOnUse'
						>
							<stop stopColor='#776FFF' />
							<stop offset={1} stopColor='#FF4694' />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div className='relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40'>
				<div className='pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32'>
					<h2 className='text-base font-semibold leading-7 text-primary'>
						Hotel News &amp; Event
					</h2>
					<p className='mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl'>
						We can't wait to welcome you
					</p>
					<p className='mt-6 text-base leading-7 text-gray-300'>
						Ready to experience the ultimate in comfort and convenience? Book your stay with us now and unlock a world of amenities and facilities that will elevate your travel experience. Whether you're visiting for business or leisure, our apartments are the perfect choice for your next destination. Don't miss out – reserve your spot today and embark on a memorable journey with us. Your comfort is our priority, and we can't wait to welcome you!
					</p>
					<div className='mt-8'>
						<a
							href='#'
							className='inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
						>
							Visit the help center
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

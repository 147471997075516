import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";

import { rooms } from "../../data/rooms";
import { capitalizeWord } from "../../utils";

type RoomListProps = {
	locationState?: string;
	accomodationType?: string;
};

export default function RoomList({
	accomodationType,
	locationState,
}: RoomListProps) {
	const [isOpen, setIsOpen] = useState(false)
	const [paymentCode, setPaymentCode] = useState(0)
	const [paymentAmount, setPaymentAmount] = useState("0")

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<div className='bg-orange-50 py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<div className='mx-auto max-w-2xl text-center'>
					<h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
						{/* @ts-ignore */}
						Available apartments in {capitalizeWord(locationState)}
					</h2>
					<p className='mt-2 text-lg leading-8 text-gray-600'>
						Explore our diverse range of accomodation options in Lagos, Nigeria, designed to cater to your every need and preference.
					</p>
				</div>
				<div className='mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
					{rooms.map((room) => (
						<Fragment key={nanoid()}>
							<article
								className='relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition ease-in-out hover:drop-shadow-xl delay-150 duration-300'
							>
								<img
									src={room.imageUrl}
									alt=''
									className='absolute inset-0 -z-10 h-full w-full object-cover'
								/>
								<div className='absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40' />
								<div className='absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10' />

								<div className='flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300'>
									<p className='mr-2 text-lg font-bold'>{room.amount}</p><span className="text-sm">({room.duration})</span>
								</div>
								<h3 className='mt-3 text-xl font-semibold leading-6 text-white'>
									<Link to={`/locations/${locationState}/${accomodationType}/${room.href}/${room.id}`}>
										{room.title}
									</Link>
								</h3>
								<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2'>
									<button
										type='button'
										className='flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50'
										onClick={() => {
											const code = Math.floor(Math.random() * 899999 + 100000);
											setIsOpen(true)
											setPaymentCode(code)
											setPaymentAmount(room.amount)
										}}
									>
										Pay {room.amount}
									</button>
									<Link to={`/locations/${locationState}/${accomodationType}/${room.href}/${room.id}`}>
										<button
											type='button'
											className='flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-primary hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50'
										>
											Learn more
										</button>
									</Link>
								</div>
							</article>
							<Transition appear show={isOpen} as={Fragment}>
								<Dialog as="div" className="relative z-10" onClose={closeModal}>
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="fixed inset-0 bg-black bg-opacity-70" />
									</Transition.Child>

									<div className="fixed inset-0 overflow-y-auto">
										<div className="flex min-h-full items-center justify-center p-4 text-center">
											<Transition.Child
												as={Fragment}
												enter="ease-out duration-300"
												enterFrom="opacity-0 scale-95"
												enterTo="opacity-100 scale-100"
												leave="ease-in duration-200"
												leaveFrom="opacity-100 scale-100"
												leaveTo="opacity-0 scale-95"
											>
												<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
													<Dialog.Title
														as="h3"
														className="text-lg font-medium leading-6 text-gray-900"
													>
														Payment details
													</Dialog.Title>
													<div className="mt-2">
														<p className="text-sm text-gray-500">
															Kindly book a reservation with the following payment details:
														</p>
														<div className="font-bold my-4 text-lg">
															<p>Account name: <span>Majestic  Courts Ltd</span></p>
															<p>Account number: <span>0842690620</span></p>
															<p>Amount: <span>{paymentAmount}</span></p>
														</div>
														<p className="text-sm text-gray-500">Kindly enter this reference code ({paymentCode}) and your phone number as the payment narration.</p>
													</div>
													<div className="mt-4">
														<button
															type="button"
															className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
															onClick={closeModal}
														>
															Got it, thanks!
														</button>
													</div>
												</Dialog.Panel>
											</Transition.Child>
										</div>
									</div>
								</Dialog>
							</Transition>
						</Fragment>
					))}
				</div>
			</div>
		</div>
	);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'react-router-dom'
import { nanoid } from "nanoid";

import { accomodationTypes } from "../../data/accomodationTypes";
import { capitalizeWord } from "../../utils";

type RoomListProps = {
	locationState?: any;
};

export default function RoomTypes({ locationState }: RoomListProps) {
	return (
		<div className='bg-orange-50 py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<div className='mx-auto max-w-2xl text-center'>
					<h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
						{/* @ts-ignore */}
						Available accomodation options in{" "}
						{capitalizeWord(locationState)}
					</h2>
					<p className='mt-2 text-lg leading-8 text-gray-600'>
						Explore our diverse range of accomodation options in Lagos, Nigeria, designed to cater to your every need and preference.
					</p>
				</div>
				<div className='mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none'>
					{accomodationTypes.map((item: any) => (
						<a href={`${locationState}/${item.url}`} key={nanoid()}>
							<article
								className='relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition ease-in-out hover:-translate-y-10 hover:scale-105 hover:drop-shadow-xl delay-150 duration-300'
							>
								<img
									src={item.imageURL}
									alt=''
									className='absolute inset-0 -z-10 h-full w-full object-cover'
								/>
								<div className='absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40' />
								<div className='absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10' />

								<div className='flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300'>
									<p className='mr-8'>{item.amount}</p>
								</div>
								<div className='mt-4 flex items-center justify-center gap-x-6'>
									<button
										className='rounded-md bg-primary px-7 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
									>
										Visit apartments
									</button>
								</div>
							</article>
						</a>
					))}
				</div>
			</div>
		</div >
	);
}

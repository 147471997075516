import React from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

const timeline = [
    {
        name: "Founded company",
        description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        date: "Aug 2021",
        dateTime: "2021-08",
    },
    {
        name: "Secured $65m in funding",
        description:
            "Provident quia ut esse. Vero vel eos repudiandae aspernatur. Cumque minima impedit sapiente a architecto nihil.",
        date: "Dec 2021",
        dateTime: "2021-12",
    },
    {
        name: "Released beta",
        description:
            "Sunt perspiciatis incidunt. Non necessitatibus aliquid. Consequatur ut officiis earum eum quia facilis. Hic deleniti dolorem quia et.",
        date: "Feb 2022",
        dateTime: "2022-02",
    },
    {
        name: "Global launch of product",
        description:
            "Ut ipsa sint distinctio quod itaque nam qui. Possimus aut unde id architecto voluptatem hic aut pariatur velit.",
        date: "Dec 2022",
        dateTime: "2022-12",
    },
];

const jobOpenings = [
    {
        id: 1,
        role: "Full-time designer",
        href: "#",
        description:
            "Quos sunt ad dolore ullam qui. Enim et quisquam dicta molestias. Corrupti quo voluptatum eligendi autem labore.",
        salary: "$75,000 USD",
        location: "San Francisco, CA",
    },
    {
        id: 2,
        role: "Laravel developer",
        href: "#",
        description:
            "Et veniam et officia dolorum rerum. Et voluptas consequatur magni sapiente amet voluptates dolorum. Ut porro aut eveniet.",
        salary: "$125,000 USD",
        location: "San Francisco, CA",
    },
    {
        id: 3,
        role: "React Native developer",
        href: "#",
        description:
            "Veniam ipsam nisi quas architecto eos non voluptatem in nemo. Est occaecati nihil omnis delectus illum est.",
        salary: "$105,000 USD",
        location: "San Francisco, CA",
    },
];

export default function About() {
    return (
        <>
            <Navigation />
            <main className='isolate'>
                {/* Hero section */}
                <div className='relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14'>
                    <div
                        className='absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96'
                        aria-hidden='true'
                    />
                    <div className='mx-auto max-w-7xl px-6 py-32 sm:py-20 lg:px-8'>
                        <div className='mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8'>
                            <h1 className='max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto'>
                                Welcome to our{" "}
                                <b>
                                    brand new luxury apartments in New Bodija Ibadan, Oyo State
                                </b>
                                .
                            </h1>
                            <div className='mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1'>
                                <p className='text-lg leading-8 text-gray-600'>
                                    Stay with us to be close to the third-largest city by
                                    population in Nigeria.
                                </p>
                                <p className='text-lg leading-8 text-gray-600 pt-5'>
                                    We are within easy reach of so many of Western’s premier
                                    attractions. Within a short drive, you’ll come to the{" "}
                                    <b>
                                        National Museum of Unity, Ibadan Zoo, Zoological Garden,
                                        Bower Memorial Tower
                                    </b>
                                    , and <b>IITA Forest Reserve</b>. Most restaurants are just a
                                    5–7-minute drive from Majestic Courts. Bottles Lounge, Ibachi
                                    Chinese Restaurant (New Bodija), Café Chrysalis (Old Bodija),
                                    and Biobak Kitchen (Old Bodija). With equal drive time, the
                                    ACE and the VENTURA Malls are close to you for your games and
                                    shopping experiences.
                                </p>
                                <p className='text-lg leading-8 text-gray-600 pt-5'>
                                    Here at Majestic Courts, we offer a variety of Royale and
                                    Supreme apartments suitable for a range of guests, whether
                                    you’re in Ibadan on business, with your partner or family for
                                    a holiday, or just visiting the great city of Ibadan. Choose
                                    from our three-bedroom Royale Apartments and three-bedroom
                                    Supreme apartments with your choice of additional space and
                                    facilities, perfect to accommodate up to five people. We
                                    understand the need for extra staff or domestic relations, our
                                    one-bedroom fully furnished BQ will cater for this at no extra
                                    cost. You are just a 3–5 minutes’ walk to MOSH, FEEDWELL, and
                                    FOODCO Supermarkets for your daily needs.
                                </p>
                            </div>
                            <img
                                src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1695147665/majestic-courts/interiors/Untitled8_y0sb1q.jpg'
                                alt=''
                                className='mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36'
                            />
                        </div>
                    </div>
                    <div className='absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32' />
                </div>
            </main>
            <Footer />
        </>
    );
}

import { useParams } from "react-router-dom";

import Navigation from "../../components/navigation";
import RoomTypes from "../../components/rooms/roomsTypes";
import CTA from "../../components/cta";
import Footer from "../../components/footer";

export default function States() {
	const { locationID } = useParams();

	return (
		<>
			<Navigation />
			<RoomTypes locationState={locationID} />
			<CTA />
			<Footer />
		</>
	);
}

import { nanoid } from "nanoid";

import { locations } from "../../data/locations";

import Location from "./Location";

export default function Locations() {
	return (
		<div className='bg-orange-50 py-24 sm:py-32' id='#locations'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<div className='mx-auto max-w-2xl text-center'>
					<h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
						Available Locations
					</h2>
					<p className='mt-2 text-lg leading-8 text-gray-600'>
						We specialize in
						providing exceptional accomodations and top-notch hospitality
						services to make your stay unforgettable. Explore our offerings and
						experience true comfort and convenience.
					</p>
				</div>
				<div className='mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
					{/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
					{locations.map((location: any) => (
						<Location
							title={location.title}
							description={location.description}
							imageURL={location.imageURL}
							url={location.url}
							key={nanoid()}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

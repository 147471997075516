import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './pages/about'
import reportWebVitals from './reportWebVitals';
import States from "./pages/states/index";
import AccomodationOptions from "./pages/accomodation-types/index";
import Room from "./pages/room/index";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/about' element={<About />} />
        <Route path='/locations/:locationID' element={<States />} />
        <Route
          path='/locations/:locationID/:accomodationType'
          element={<AccomodationOptions />}
        />
        <Route
          path='/locations/:locationID/:accomodationType/:room/:roomID'
          element={<Room />}
        />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ROUTES } from "../routes";

export const accomodationTypes = [
	{
		title: "Apartments",
		url: ROUTES.APARTMENTS,
		description:
			"Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
		imageURL:
			"https://res.cloudinary.com/dp8g2jwak/image/upload/v1691948320/majestic-courts/options/iuqweoqw_rnkf7o.jpg",
	},
	// {
	// 	title: "Hotels (coming soon)",
	// 	description:
	// 		"Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
	// 	imageURL:
	// 		"https://res.cloudinary.com/dp8g2jwak/image/upload/v1691316830/majestic-courts/renders/4cd716f4-e459-4786-8868-c5c74fa5cfb0_h2nsq3.jpg",
	// },
];

import { ROUTES } from "../routes";

export const locations = [
	{
		title: "Ibadan",
		url: ROUTES.IBADAN,
		description:
			"Experience the height of comfort and luxury at our Ibadan apartments. Immerse yourself in the rich cultural heritage of this historic city while enjoying the finest amenities and impeccable service. Your ideal stay in Ibadan awaits you.",
		imageURL:
			"https://res.cloudinary.com/dp8g2jwak/image/upload/v1691316824/majestic-courts/renders/a4ca7695-cfd5-44c6-841b-2f172324845d_s4szeb.jpg",
	},
	{
		title: "Lagos (coming soon)",
		description:
			"Discover the epitome of comfort and luxury at our Lagos apartments. Immerse yourself in the vibrant culture of this bustling city while enjoying the finest amenities and impeccable service. Your perfect stay in Lagos awaits you.",
		imageURL:
			"https://res.cloudinary.com/dp8g2jwak/image/upload/v1691316823/majestic-courts/renders/c62ca89f-f12f-4996-a2fa-30a8a27d20eb_f1j8dv.jpg",
	},
];

import { useParams } from "react-router-dom";

import Navigation from "../../components/navigation";
import RoomList from "../../components/rooms/roomsList";
import CTA from "../../components/cta";
import Footer from "../../components/footer";

export default function AccomodationOptions() {
	const { locationID, accomodationType } = useParams();

	return (
		<>
			<Navigation />
			<RoomList
				locationState={locationID}
				accomodationType={accomodationType}
			/>
			<CTA />
			<Footer />
		</>
	);
}

import Introduction from "./components/introduction";
import Hero from "./components/hero";
import Navigation from "./components/navigation";
import Locations from "./components/locations/Locations";
import Amenities from "./components/amenities";
import CTA from "./components/cta";
import Footer from "./components/footer";

function App() {
  return (
    <>
      <Navigation />
      <Hero />
      <Locations />
      <Introduction />
      <Amenities />
      <CTA />
      <Footer />
    </>
  );
}

export default App;

import { nanoid } from "nanoid";

type LocationProps = {
	title: string;
	imageURL: string;
	url: string;
	description: string;
};

export default function Location({
	title,
	imageURL,
	url,
	description,
}: LocationProps) {
	return (
		<article
			key={nanoid()}
			className='relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition ease-in-out hover:-translate-y-5 hover:scale-105 hover:drop-shadow-xl delay-150 duration-300'
		>
			<img
				src={imageURL}
				alt=''
				className='absolute inset-0 -z-10 h-full w-full object-cover'
			/>
			<div className='absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40' />
			<div className='absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10' />

			<div className='flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300'>
				<p className='mr-8'>{description}</p>
			</div>
			<h3 className='mt-3 text-lg lg:text-5xl font-semibold leading-6 text-white'>
				<a href={url}>
					<span className='absolute inset-0' />
					{title}
				</a>
			</h3>
		</article>
	);
}

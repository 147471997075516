export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export function capitalizeWord(word: string) {
	const str =
		word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
	return str;
}

export function removeHyphenAndCapitalizeWord(word: string) {
	const info = word;
	const newInfo = info.replace("-", " ");
	const newWord =
		newInfo.charAt(0).toUpperCase() + newInfo.substring(1).toLowerCase();
	return newWord;
}

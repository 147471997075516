export const rooms = [
	{
		id: "fc18e267-2a27-4554-a599-42228a4fa54d",
		title: "Royal Three Bedroom Apartments",
		href: "royal-three-bedroom-apartments",
		description:
			"Experience unparalleled luxury and comfort in our Royal three bedroom apartments. These exquisite spaces are designed to cater to your every desire, featuring a spacious bedroom with a plush double bed, ensuring a restful night's sleep. The elegantly furnished living area provides the perfect ambiance for relaxation, while a fully equipped kitchenette allows you to indulge your culinary creativity. Immerse yourself in the epitome of style, convenience, and sophistication during your stay in these luxury one-bedroom apartments.",
		imageUrl:
			"https://res.cloudinary.com/dp8g2jwak/image/upload/v1695147678/majestic-courts/interiors/Untitled9_iau6sy.jpg",
		amount: "NGN200,000",
		duration: 'Per night',
		ratings: 5,
		highlights: [
			"24/7 Access to the gym",
			"Comfortable beds",
			"Fast and reliable internet access",
			"Bigger dining area",
			"More car park space"
		],
		date: "Mar 16, 2020",
		datetime: "2020-03-16",
	},
	{
		id: "a0c29042-89db-4e3d-ae53-e9ab5e4d5413",
		title: "Supreme Three Bedroom Apartments",
		href: "supreme-three-bedroom-apartments",
		description:
			"Elevate your stay to the pinnacle of sophistication in our Supreme three bedroom apartments. These tastefully designed spaces offer the perfect blend of style and functionality. You'll find a generously sized bedroom with twin beds, promising a peaceful night's sleep. The living area is adorned with elegant furnishings, creating an inviting atmosphere for relaxation and entertainment. Our fully equipped kitchenette ensures you have all the amenities you need at your fingertips. Embrace the luxury and versatility of our twin-bed one-bedroom apartments for an unforgettable experience in comfort and opulence.",
		imageUrl:
			"https://res.cloudinary.com/dp8g2jwak/image/upload/v1695147665/majestic-courts/interiors/Untitled6_oigld1.jpg",
		amount: "NGN180,000",
		duration: 'Per night',
		ratings: 5,
		highlights: [
			"24/7 Access to the gym",
			"Comfortable beds",
			"Fast and reliable internet access",
		],
		date: "Mar 16, 2020",
		datetime: "2020-03-16",
	},
];

import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { FaSquareXTwitter, FaWhatsapp } from 'react-icons/fa6';
import {
	Bars3Icon,
	EnvelopeIcon,
	QuestionMarkCircleIcon,
	PhoneIcon,
} from "@heroicons/react/24/outline";
import { nanoid } from "nanoid";

import { company } from "../../constants/company";
import { ROUTES } from "../../routes";

const navigation = [
	{ name: "Home", href: ROUTES.HOME },
	{ name: "Rooms", href: ROUTES.ROOMS },
	{ name: "Conference & Meetings", href: "#" },
	{ name: "About", href: ROUTES.ABOUT },
	{ name: "Contact", href: "#" },
]

const socialNavigation = [
	{
		name: "Facebook",
		href: company.social.facebook,
		icon: (props: any) => (
			<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
				<path
					fillRule='evenodd'
					d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
					clipRule='evenodd'
				/>
			</svg>
		),
	},
	{
		name: "Instagram",
		href: company.social.instagram,
		icon: (props: any) => (
			<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
				<path
					fillRule='evenodd'
					d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
					clipRule='evenodd'
				/>
			</svg>
		),
	},
	{
		name: "Twitter",
		href: company.social.twitter,
		icon: FaSquareXTwitter,
	},
]


export default function Navigation() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const whiteRoutes = [ROUTES.HOME, ROUTES.ABOUT]

	const location = useLocation()
	const homeRoute = whiteRoutes.includes(location.pathname)

	return (
		<>
			{/* Hero section */}
			<div className='relative bg-gray-900'>
				{/* Navigation */}
				<header className='relative z-10'>
					<nav aria-label='Top'>
						{/* Top navigation */}
						<div className="hidden lg:block bg-gray-900">
							<div className='mx-auto flex h-10 max-w-7xl items-center justify-start lg:justify-between px-4 sm:px-6 lg:px-8'>
								<div className='flex items-center space-x-6'>
									{socialNavigation?.map((item) => (
										<a
											key={item.name}
											href={item.href}
											className='flex text-gray-400 hover:text-gray-500'
										>
											<span className='sr-only'>{item.name}</span>
											<item.icon className='h-6 w-6' aria-hidden='true' />
										</a>
									))}
								</div>
								<div className='flex items-center space-x-6'>
									<a
										href="https://wa.me/2347047002011"
										className='flex items-center text-sm font-medium text-white hover:text-gray-100'
									>
										<FaWhatsapp className="h-6 w-6 text-white mr-1" /> +234 704 700 2011
									</a>
									<a
										href="mailto:info@majesticcourts.ng"
										className='flex items-center text-sm font-medium text-white hover:text-gray-100'
									>
										<EnvelopeIcon className="h-6 w-6 pr-1 text-white" />{" "} info@majesticcourts.ng
									</a>
								</div>
							</div>
						</div>

						{/* Secondary navigation */}
						<div className="lg:py-4">
							<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>

								<div className='flex h-16 items-center justify-between'>
									{/* Logo (lg+) */}
									<div className='hidden lg:flex lg:flex-1 lg:items-center'>
										<a href={ROUTES.HOME}>
											<span className='sr-only'>{company.name}</span>
											<img
												className='h-24 w-auto'
												src={homeRoute ? "https://res.cloudinary.com/dp8g2jwak/image/upload/v1695492256/majestic-courts/logo_2x_jogeot_png_ottu8y.png" : "https://res.cloudinary.com/dp8g2jwak/image/upload/v1690565094/majestic-courts/logo_2x_jogeot.png"}
												alt=''
											/>
										</a>
									</div>
									<div className='hidden h-full lg:flex'>
										{/* Flyout menus */}
										<Popover.Group className='inset-x-0 bottom-0 px-4'>
											<div className='flex h-full justify-center space-x-8'>
												{navigation?.map((page: any) => (
													<a
														key={page.name}
														href={page.href}
														className='flex items-center text-sm font-medium text-white'
													>
														{page.name}
													</a>
												))}
											</div>
										</Popover.Group>
									</div>

									{/* Mobile menu and search (lg-) */}
									<div className='flex flex-1 items-center lg:hidden'>
										<button
											type='button'
											className='-ml-2 p-2 text-white'
											onClick={() => setMobileMenuOpen(true)}
										>
											<span className='sr-only'>Open menu</span>
											<Bars3Icon className='h-6 w-6' aria-hidden='true' />
										</button>
									</div>

									{/* Logo (lg-) */}
									<a href={ROUTES.HOME} className='lg:hidden'>
										<span className='sr-only'>{company.name}</span>
										<img
											src={homeRoute ? "https://res.cloudinary.com/dp8g2jwak/image/upload/v1695492256/majestic-courts/logo_2x_jogeot_png_ottu8y.png" : "https://res.cloudinary.com/dp8g2jwak/image/upload/v1690565094/majestic-courts/logo_2x_jogeot.png"}
											alt=''
											className='h-16 w-auto'
										/>
									</a>

									<div className='flex flex-1 items-center justify-end'>
										<div className='flex items-center lg:ml-8'>
											{/* Help */}
											<a href={ROUTES.IBADAN} className='p-2 text-white lg:hidden'>
												<span className='sr-only'>Help</span>
												<QuestionMarkCircleIcon
													className='h-6 w-6'
													aria-hidden='true'
												/>
											</a>
											<Link to={ROUTES.IBADAN}>

												<button className='px-6 py-2.5 rounded-md bg-primary transition ease-in-out duration-300 delay-150 hover:scale-110 hidden text-sm font-medium text-white lg:block'>
													Book a Room
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>
			</div>
		</>
	);
}
import { ROUTES } from '../../routes'

export default function Amenities() {
	return (
		<div className='overflow-hidden bg-gray-50 py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:flex lg:px-8'>
				<div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8'>
					<div className='lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8'>
						<h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
							Our Amenities
						</h2>
						<p className='mt-6 text-xl leading-8 text-gray-600'>
							Our hotels and apartments offer a wide array of amenities and facilities to enhance your stay. From well-appointed rooms and spacious living areas to fully-equipped kitchens, we've designed our spaces with your comfort in mind.
						</p>
						<p className='mt-6 text-base leading-7 text-gray-600'>
							Enjoy the convenience of high-speed Wi-Fi, ensuring you stay connected throughout your visit. Whether you're here for business or leisure, our workspaces and business centers are perfect for productivity.
						</p>
						<div className='mt-10 flex'>
							<a
								href={ROUTES.IBADAN}
								className='rounded-md bg-primary px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
							>
								Book Now <span aria-hidden='true'>&rarr;</span>
							</a>
						</div>
					</div>
					<div className='flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents'>
						<div className='w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end'>
							<img
								src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1695147665/majestic-courts/interiors/Untitled6_oigld1.jpg'
								alt=''
								className='aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover'
							/>
						</div>
						<div className='contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8'>
							<div className='order-first flex w-64 flex-none justify-end self-end lg:w-auto'>
								<img
									src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1695147665/majestic-courts/interiors/Untitled3_evq5sn.jpg'
									alt=''
									className='aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover'
								/>
							</div>
							<div className='flex w-96 flex-auto justify-end lg:w-auto lg:flex-none'>
								<img
									src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1691329370/majestic-courts/room-pics/DJI_0176-min_oshaob.jpg'
									alt=''
									className='aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover'
								/>
							</div>
							<div className='hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none'>
								<img
									src='https://res.cloudinary.com/dp8g2jwak/image/upload/v1691329377/majestic-courts/room-pics/DJI_0186-min_vvmb3g.jpg'
									alt=''
									className='aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
